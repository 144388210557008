<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>综合管理</el-breadcrumb-item>
			<el-breadcrumb-item>信批验证出票</el-breadcrumb-item>
		</el-breadcrumb>
		<!--信披验证票据信息-->
		<el-card>
			<div class="title-one">
				<span>信披验证票据信息</span>
				<span>温馨提示：此票仅用于信息披露平台绑定验证，到期自动清退，无需兑付。</span>
			</div>
			<ul class="list-special">
				<li>
					<span>签票验证票面金额（元）：</span>
					<el-input v-model="verifyBillAmount"></el-input>
				</li>
				<li>
					<span>出票日期：</span>
					<em>{{publicInfo.drawBillDate}}</em>
				</li>
				<li>
					<span>票据到期日：</span>
					<em>{{publicInfo.billDueDate}}</em>
				</li>
				<li>
					<span>票据种类：</span>
					<em>{{publicInfo.billType}}</em>
				</li>
			</ul>
		</el-card>
		<!--出票人-->
		<el-card>
			<h3>出票人</h3>
			<ul class="list">
				<li>
					<span>出票人：</span>
					<em>{{publicInfo.cprName}}</em>
				</li>
				<li>
					<span>出票人账号：</span>
          <el-select v-model="changeInfo.accountNo" placeholder="请选择" @change="handleOpenBankNo">
            <el-option
                v-for="item in cprAccountNoList"
                :key="item.lxBankId"
                :label="item.accountNo"
                :value="item.accountNo">
            </el-option>
          </el-select>
				</li>
				<li>
					<span>开户行：</span>
					<em>{{changeInfo.openBank}}</em>
				</li>
				<li>
					<span>开户行行号：</span>
					<em>{{changeInfo.openBankNo}}</em>
				</li>
			</ul>
		</el-card>
		<!--承兑人-->
		<el-card>
			<h3>承兑人</h3>
			<ul class="list">
				<li>
					<span>承兑人：</span>
					<em>{{publicInfo.cprName}}</em>
				</li>
				<li>
					<span>承兑人账号：</span>
					<em>{{changeInfo.accountNo}}</em>
				</li>
				<li>
					<span>开户行：</span>
					<em>{{changeInfo.openBank}}</em>
				</li>
				<li>
					<span>开户行行号：</span>
					<em>{{changeInfo.openBankNo}}</em>
				</li>
			</ul>
		</el-card>
		<!--收票人-->
		<el-card>
			<h3>收票人</h3>
			<ul class="list">
				<li>
					<span>收票人：</span>
					<em>{{publicInfo.sprName}}</em>
				</li>
				<li>
					<span>收票人账号：</span>
					<em>{{publicInfo.sprAccountNo}}</em>
				</li>
				<li>
					<span>开户行：</span>
					<em>{{publicInfo.sprOpenBank}}</em>
				</li>
				<li>
					<span>开户行行号：</span>
					<em>{{publicInfo.sprOpenBankNo}}</em>
				</li>
			</ul>
		</el-card>
		<el-button type="primary" class="confirm-submit" @click="handleSuccess">确认提交</el-button>

    <!--短信验证-->
    <el-dialog
        title="短信验证"
        :visible.sync="messageDialogVisible"
        :close-on-click-modal="false"
        width="40%"
        @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号" prop="operatorMobile">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
    <!--应答弹框-->
    <el-dialog :visible.sync="successDialogVisible"
               width="40%"
               :show-close="false"
               :close-on-click-modal="false"
               @close="$router.go(-1)">
      <div class="agree-dialog">
        <img src="~@/assets/img/ticket/cg.png" alt="">
        <h3>信披验证出票提交成功</h3>
        <div class="copy-code">
          操作编号：<h4>{{copyCode}}</h4>
        </div>
        <el-button type="primary" @click="handleCopy">点击复制</el-button>
        <div class="success-dialog-tips">温馨提示： <p>您可在任务查询中查看出票进度。出票成功后，请将票据包号复制到信息披露平台—电票账户绑定页面。</p></div>
      </div>
      <span slot="footer" class="dialog-footer">
		    <el-button @click="successDialogVisible = false">关 闭</el-button>
		  </span>
    </el-dialog>
	</div>
</template>

<script>
  /*倒计时按钮*/
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: "creditVerifyTicket",
    components: {
      DelayButton
    },
    mounted() {
      // 获取出票人账号
      this.getAllBank()
      // 获取数据
      this.getApplyCreditPublishInfo()
    },
    data(){
      return {
        verifyBillAmount: null, // 签票验证票面金额（元）
        cprAccountNoList: [], // 出票人账号列表
        publicInfo: { // 初始化数据
          drawBillDate: '', // 出票日期
          billDueDate: '', // 票据到期日
          billType: '', //  票据种类
          cprName: '', // 出票人-出票人
          sprName: '', // 收票人
          sprAccountNo: '', // 收票人-收票人账号
          sprOpenBank:'',
          sprOpenBankNo: ''
        },
        // 出票人账号选择后的数据
        changeInfo: {
          accountNo: '', // 出票人账号
          openBank: '', // 开户行
          openBankNo: '', // 开户行行号
        },
        mobile: this.$store.state.getUser, // 手机号
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
        successDialogVisible: false, // 控制成功弹框的显示与隐藏
        copyCode:'', // 成功后的操作编号
      }
    },
    methods: {
      // 获取出票人账号
      async getAllBank(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/creditPublish/getAllBank');
        if(res.code !== 200) return this.$message.error(res.msg)
        this.cprAccountNoList = res.data
      },
      // 获取数据
      async getApplyCreditPublishInfo(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/creditPublish/getApplyCreditPublishInfo');
        if(res.code !== 200) return this.$message.error(res.msg)
        this.publicInfo = {...res.data}
      },
      // 监听出票人账号
      handleOpenBankNo(value){
        let result = this.cprAccountNoList.filter(item => item.accountNo == value)
        this.changeInfo = result[0]
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.copyCode).then(res => {
          this.$message.success('复制成功')
          this.agreeDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.agreeDialogVisible = true
        })
      },
      // 确认提交
      handleSuccess(){
        if (!this.verifyBillAmount){
          this.$message.error('签票验证票面金额不能为空')
        }else if(!this.changeInfo.accountNo){
          this.$message.error('出票人账号账号不能为空')
        }else {
          this.messageDialogVisible = true
        }
      },
      // 短信验证框提交
      handleSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return;
          let params = {
            smsCode: this.messageForm.verifyCode, // 短信验证码
            verifyBillAmount: this.verifyBillAmount, // 签票验证票面金额（元）
            cprAccountNo: this.changeInfo.accountNo, // 出票人账号
            cprOpenBank: this.changeInfo.openBank,  // 出票人开户行
            cprOpenBankNo: this.changeInfo.openBankNo, // 出票人开户行行号
            cdrAccountNo: this.changeInfo.accountNo, // 承兑人账号
            cdrOpenBank: this.changeInfo.openBank, // 承兑人开户行
            cdrOpenBankNo: this.changeInfo.openBankNo // 承兑人开户行行号
          }
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/creditPublish/addAndVerify', this.$qs.stringify(params))
          if (res.code === 200){
            this.messageDialogVisible = false
            this.successDialogVisible = true
            this.copyCode = res.data
          }else {
            this.messageDialogVisible = true
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
	.title-one{
		span{
			display: inline-block;
		}
		span:first-child{
			font-size: 16px;
			color: #24262B;
			font-weight: bold;
			margin-right: 10px;
		}
		span:nth-of-type(2){
			font-size: 12px;
			color: #666;
			i{
				color: #D6000F;
			}
		}
	}
	.list-special, .list{
		display: flex;
		flex-wrap: wrap;
		li {
			width: 50%;
			margin-top: 20px;
			> span, label {
				display: inline-block;
				width: 180px;
				text-align: right;
			}
			span::before {
				display: inline-block;
				content: "*";
				color: #c70009;
				margin-right: 3px;
			}
			> .el-input{
				width: 50%;
			}
		}
	}
	.list{
		li{
			span{
				width: 100px;
			}
      /deep/ .el-select{
        width: 70%;
      }
		}
	}
	.confirm-submit{
		display: block;
		width: 220px;
		margin: 30px auto;
	}
  /deep/ .verify-code{
    width: 220px;
  }
  /*成功弹框*/
  /deep/ .agree-dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
      margin-top: 20px;
    }
    .copy-code{
      margin-top: 15px;
      h4{
        display: inline-block;
        color: #C70009;
      }
    }
    .el-button{
      margin: 30px 24px 0;
    }
    .success-dialog-tips{
      margin-top: 30px;
      p{
        color: #c70009;
      }
    }
  }

</style>